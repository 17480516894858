import React, { useState } from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Row, Container, Col, Card, Accordion } from 'react-bootstrap/'
import { FaSortDown, FaCircle } from 'react-icons/fa';
import scrollTo from 'gatsby-plugin-smoothscroll';
import TopHeader from "../components/top-header"

import RetmesAIPDF from "../../static/docs/retmes-ai-v1.pdf"
// import RetmesAILogo from "../images/retmes-ai/retmes-ai-logo.svg"
import RetmesAILogoWhite from "../images/retmes-ai/retmes-ai-white.svg"
import RetmesOKIcon from "../images/retmes-ai/retmes-ok.svg"
import DownloadDoc from "../images/retmes-ai/retmes-ai-pdf-doc-2.png"

import EmptyCanvasHiw from "../images/retmes-ai/retmes-ai-emtpy-canvas-hiw.svg"
import Number1Pin from "../images/retmes-ai/retmes-ai-pin-number-1.svg"
import Number2Pin from "../images/retmes-ai/retmes-ai-pin-number-2.svg"
import Number3Pin from "../images/retmes-ai/retmes-ai-pin-number-3.svg"
import Number4Pin from "../images/retmes-ai/retmes-ai-pin-number-4.svg"
// import DefaultPin from "../images/retmes-ai/retmes-ai-default-pin.svg"
// import LivePin from "../images/retmes-ai/retmes-ai-live-pin.svg"

import DataCollect from "../images/retmes-ai/retmes-ai-data.svg"
import SecurityIcon from "../images/retmes-ai/retmes-security.svg"
import RetmesOEE from "../images/retmes-ai/retmes-ai-oee.svg"
import DataChangeIcon from "../images/retmes-ai/retmes-ai-data-collect-icon.svg"
import SocialCard from "../images/social-cards/retmes-ai-card.png"
import { injectIntl, FormattedMessage, Link } from "gatsby-plugin-intl"

const DefaultText = 'Yapay Zeka Projelerinde yapılması gereken  ve projenin yöntem ve yaklaşımlarının belirleneceği ilk adım problem tespiti ve problemi iyi anlamaktır. Problem tespiti ve problemi iyi anlamak  çözümüne uygun araçların seçimi, verilerin toplanması, mevcut durum tespiti ve  alınacak aksiyonların belirlenmesi için önceliklidir.'
const DefaultImageAlt = 'Retmes AI Yapay Zeka'

const RetmesAI = ({ intl }) => {

  const [isClick, setPinToggle] = useState(true);
  const [isClick2, setPinToggle2] = useState(true);
  const [isClick3, setPinToggle3] = useState(true);
  const [isClick4, setPinToggle4] = useState(true);

  function PinToggle() {
    if (!isClick) setPinToggle(true)
    else (setPinToggle(false) || setPinToggle2(true) || setPinToggle3(true) || setPinToggle4(true));
  }

  function PinToggle2() {
    if (!isClick2) setPinToggle2(true);
    else (setPinToggle2(false) || setPinToggle(true) || setPinToggle3(true) || setPinToggle4(true));
  }

  function PinToggle3() {
    if (!isClick3) setPinToggle3(true);
    else (setPinToggle3(false) || setPinToggle(true) || setPinToggle2(true) || setPinToggle4(true));
  }

  function PinToggle4() {
    if (!isClick4) setPinToggle4(true);
    else (setPinToggle4(false) || setPinToggle(true) || setPinToggle2(true) || setPinToggle3(true));
  }



  return (
    <Layout>
      <TopHeader />
      <SEO
        title="Retmes AI - Yapay Zekâ, Büyük Veri ve Derin Öğrenme Bir Arada"
        description="Retmes AI, veri toplama süreçlerini insandan bağımsız, kaynaktan büyük veriyi toplar, güvenli bir şekilde analiz eder ve büyük veriyle üst düzey analitik çalışmalar yapılmasına olanak sağlar."
        image={SocialCard} />
      <section className='retmes-ui-section'>
        <Container>
  
        </Container>
        <div className="retmes-ai-hero-container">
          <div className="retmes-ai-hero-container-inside">
            <div className="retmes-ai-hero-image-centered">
              <img className='mt-2' src={RetmesAILogoWhite} alt={DefaultImageAlt} />
            </div>
          </div>
        </div>

        <Container>
          <h1>Retmes AI Nedir?</h1>
          <Row>
            <Col sm className='retmes-lp-ml-col-2'>
              <br />
              <p>
                <b className='bold'>Retmes AI</b> Yapay zeka projelerini veri toplama, analiz, modelleme ve model çıktılarının alınacağı dört başlıkta toplarsak Retmes AI sizlere bir yapay zeka projesinin tüm aşamaları için kapsamlı uçtan uca bir platform sunar. Retmes AI özellikle üretimde yapay zekanın kullanılması için oluşturulmuş bir yapay zeka platformudur. Retmes AI ile üretimden veri toplama süreçlerini insandan bağımsız, verinin üretildiği kaynaktan otomatik olarak toplayabilirsiniz. Toplanan verileri analiz edebilir ve yapay zeka projeniz için en uygun modeli eğitebilir veya modelinizin çıktılarını üretime  entegre edebilirsiniz. Makine öğrenmesi(machine learning), derin öğrenme (deep learning) gibi yapay zekanın farklı yaklaşımları ile üretim sisteminizde verimliliğinizi arttırabilirsiniz. Kestirimci kalite uygulamaları ile olası kalite problemlerini önceden tespit edebilirsiniz ve kalitesizlik sorunu oluşmadan sisteminize müdahale edebilirsiniz. Kestirimci bakım uygulamaları ile kayıpları ve duruşları azaltabilirsiniz veya duruş olmaması için sisteminizde ki anomalileri tespit edebilirsiniz. Enerji üretim/tüketim sistemlerinizi yapay zeka ile modelleyip enerji verimliliğinizi arttırabilirsiniz. Yapay zeka ile proseslerinizi optimize edebilir ve verimli bir üretim için üretiminizi kontrol altına alabilirsiniz.

     Böylece Retmes AI ile büyük veriyi (Big Data) işleyerek ileri analitik yapay zekâ projeleri gerçekleştirmenin yolu açılmış olur.

            </p>
              <Link className='call2link' to='/iletisim'>
                <div className='retmes-call-2-button'>
                  <p><FormattedMessage id="Contact_to_us" /></p>
                </div>
              </Link>
            </Col>
          </Row>
          <span className="scroll-btn">
            <span class="mouse blue">
              <span>
              </span>
            </span>
          </span>
        </Container>
      </section>
      <svg viewBox="0 0 1913.4 217.1">
        <defs>
        </defs>
        <path className='background-wave' d="M1913.4 122.8c-52.5 21.1-106.1 38.9-160.5 53.3-505.3 133.7-802.3-105.7-1355-65.3-86.9 6.4-230.4 23.3-408.4 78.3l4-212.9c0-.3.3-.3.4-.5 370 16 740 22.7 1109.8 20.2 269.9-1.8 539.8-8.6 809.7-20.2v147.1z" fill="white" stroke="#000" strokeMiterlimit="10" />
      </svg>
      <Container>
        <h2>Retmes AI Nasıl Çalışır?</h2>
        <Row>
          <figure className='retmes-map-figure' style={{ position: 'relative' }}>
            <img className='retmes-map-image' src={EmptyCanvasHiw} alt="retmes map" />
            <div className='retmes-ai-default-pin-section-1'>
              <img onClick={PinToggle} className='retmes-ai-pin' src={Number1Pin} alt="retmes default pin" />
              <div className={`${!isClick ? 'retmes-ai-pin-bubble cursor' : 'retmes-ai-pin-bubble-none'}`}>
                <div className='retmes-pin-bubble-name' onClick={() => scrollTo('#to-accordion')}><p> Tasarım</p></div>
                <div className='retmes-pin-bubble-desc'><p>Üretim sahasındaki problemlerin tespit edilmesi ve verilerin toplanıp Büyük Veri'nin oluşturulması.</p></div>
              </div>
            </div>
            <div className='retmes-ai-default-pin-section-2'>
              <img onClick={PinToggle2} className='retmes-ai-pin' src={Number2Pin} alt="retmes default pin" />
              <div className={`${!isClick2 ? 'retmes-ai-pin-bubble cursor ' : 'retmes-ai-pin-bubble-none'}`}>
                <div className='retmes-pin-bubble-name' onClick={() => scrollTo('#to-accordion')}><p>Analiz</p></div>
                <div className='retmes-pin-bubble-desc'><p>Büyük Veri'nin analiz, veri isetlerinin oluşturulup görselleştirilmesi. </p></div>
              </div>
            </div>
            <div className='retmes-ai-default-pin-section-3'>
              <img onClick={PinToggle3} className='retmes-ai-pin' src={Number3Pin} alt="retmes default pin" />
              <div className={`${!isClick3 ? 'retmes-ai-pin-bubble cursor' : 'retmes-ai-pin-bubble-none'}`}>
                <div className='retmes-pin-bubble-name' onClick={() => scrollTo('#to-accordion')}><p>Modelleme</p></div>
                <div className='retmes-pin-bubble-desc'><p>Veri modellerinin oluşturulup eğitilmesi ve en iyi modelin belirlenip yayınlanması.</p></div>
              </div>
            </div>
            <div className='retmes-ai-default-pin-section-4'>
              <img onClick={PinToggle4} className='retmes-ai-pin' src={Number4Pin} alt="retmes default pin" />
              <div className={`${!isClick4 ? 'retmes-ai-pin-bubble cursor' : 'retmes-ai-pin-bubble-none'}`}>
                <div className='retmes-pin-bubble-name' onClick={() => scrollTo('#to-accordion')}><p>Entegrasyon</p></div>
                <div className='retmes-pin-bubble-desc'><p>Testlerden sonra proje canlıya alınarak entegrasyon süreci tamamlanır.</p></div>
              </div>
            </div>
          </figure>
        </Row>



        <Row className='align-items-center mt-0 mb-5'>
          <Col sm className='retmes-lp-ml-col-2'>
            <h2>Veri Toplama ve Büyük Veri</h2>
            <p>
              Problemin çözümü için üretime ait proses parametreleri, iş emirleri, duruşlar, kalite gibi problem çözümünde kullanılacak veriler Gateway, Akıllı Sensörler, Kablosuz Sensörler gibi <b className='bold'>IIoT</b> donanımları ile toplanarak büyük veri oluşturulur.
            </p>
          </Col>
          <Col sm className='retmes-lp-ml-col-2 m-auto center text-center'
            sm={4}>
            <img style={{ width: '7rem' }} src={DataCollect} alt={DefaultImageAlt} />
          </Col>
        </Row>



        <Row className='align-items-center flex-wrap-reverse  mt-5 mb-5'>
          <Col sm className='retmes-lp-ml-col-2 m-auto center text-center'
            sm={4}>
            <img style={{ width: '10rem' }} src={RetmesOKIcon} alt={DefaultImageAlt} />
          </Col>
          <Col sm className='retmes-lp-ml-col-2'>
            <h2 id='veri'>Veri Analizi ve Modelleme</h2>
            <p>
              <b className='bold'>  Retmes AI </b> üzerinde toplanan veril


             <b className='bold'>  Retmes AI </b> üzerinde entegre olan analiz yazılımları ve teknolojileri ile de veri analizi yapılabilir veya  <b className='bold'>  Retmes AI </b> harici kullanılan <b className='bold'>DSP</b> platformlarında da analiz edilmesi için gerekli olan veri aktarımı veya analiz çıktıları  <b className='bold'>  Retmes AI </b> ortamına entegrasyon servisleri ile entegre olabilir.
            </p>
          </Col>
        </Row>

        <Row className='align-items-center  mt-5 mb-5'>
          <Col sm className='retmes-lp-ml-col-2'>
            <h2>Veritabanı</h2>
            <p>
              <b className='bold'>  Retmes AI </b> üzerinde bulunan haberleşme protokolleri ile sahadan toplanmak istenilen verileri toplar veya elde mevcut veriler var ise bunlar için entegrasyon servisleri ile veriler istenilen veritabanı platformundan içeriye aktarılabilir.
            </p>
          </Col>
          <Col sm className='retmes-lp-ml-col-2 m-auto center text-center'
            sm={4}>
            <img style={{ width: '20rem' }} src={DataChangeIcon} alt={DefaultImageAlt} />
          </Col>
        </Row>

        <Row className='align-items-center flex-wrap-reverse  mt-5 mb-5'>
          <Col sm className='retmes-lp-ml-col-2 m-auto center text-center'
            sm={4}>
            <img style={{ width: '10rem' }} src={SecurityIcon} alt={DefaultImageAlt} />
          </Col>
          <Col sm className='retmes-lp-ml-col-2'>
            <h2>Güvenli Veri Transferi</h2>
            <p>
              <b className='bold'>  Retmes AI </b> sisteminde toplanılan ve analiz edilen tüm veriler kriptolanarak işlenir.
            </p>
          </Col>
        </Row>

        <Row className='align-items-center  mt-5 mb-5'>
          <Col sm className='retmes-lp-ml-col-2'>
            <h2>Yüksek Verimlilik</h2>
            <p>
              <b className='bold'>Retmes AI, </b> büyük veri analizleri ve derin öğrenme algoritmalarıyla, işletmenizde  yüksek <Link to='/oee-nedir'><b className='bold'>OEE</b></Link> oranı sağlar.
            </p>
          </Col>
          <Col sm className='retmes-lp-ml-col-2 m-auto center text-center'
            sm={4}>
            <img style={{ width: '20rem' }} src={RetmesOEE} alt={DefaultImageAlt} />
          </Col>
        </Row>

        <Row className='home-row-2' style={{ paddingBottom: '0' }}>
          <Col sm style={{ alignSelf: 'center', marginTop: '1em', marginBottom: '0px' }} className='head-col-1'>
            <a href={RetmesAIPDF}> <img className='document-image mb-4' style={{ width: '10rem' }} src={DownloadDoc} alt="Retmes AI PDF Dokümanı" />
              <p><b className='bold'>Hemen Dokümanı İndirin</b></p>
            </a>
          </Col>
        </Row>
      </Container>
      <svg
        style={{
          transform: 'rotate(180deg)'
        }}
        viewBox="0 0 1913.4 217.1">
        <defs>
        </defs>
        <path className='background-wave' d="M1913.4 122.8c-52.5 21.1-106.1 38.9-160.5 53.3-505.3 133.7-802.3-105.7-1355-65.3-86.9 6.4-230.4 23.3-408.4 78.3l4-212.9c0-.3.3-.3.4-.5 370 16 740 22.7 1109.8 20.2 269.9-1.8 539.8-8.6 809.7-20.2v147.1z" fill="white" stroke="#000" strokeMiterlimit="10" />
      </svg>
      <section className='retmes-ui-section'>
        <Container>
          <Accordion className='ai-accordion'>
            <Card className='ai-card' id='to-accordion'>
              <Accordion.Toggle as={Card.Header} className='justify-content-between' eventKey="0">
                <FaCircle style={{ fill: '#67a9c6' }} /><h3 className='bold'>Tasarım</h3>  <FaSortDown />
              </Accordion.Toggle>
              <Accordion.Collapse eventKey="0">
                <Card.Body>
                  <h4 className='bold'>Problem Tespiti</h4>
                  <p>
                    Yapay Zekâ projelerinde yapılması gereken ve projenin yöntem ve yaklaşımlarının belirleneceği ilk adım problem tespiti ve problemi iyi anlamaktır. Problem tespiti ve problemi iyi anlamak  çözümüne uygun araçların seçimi, verilerin toplanması, mevcut durum tespiti ve alınacak aksiyonların belirlenmesi için önceliklidir.
              </p>
                  <h4 className='bold'>Veri Toplama</h4>
                  <p>
                    Problem tespiti ve problemin çözümüne ilişkin yapılacak ilk çalışma, uygun durum tespiti sonrası problemin çözümünde kullanılacak büyük verinin oluşturulmasıdır. Problemin çözümü için üretime ait proses parametreleri, iş emirleri, duruşlar, kalite gibi problem çözümünde kullanılacak verilerin ilgili üretim noktalarından veri toplama servisleri ve araçları ile toplanması gerekir ve/veya bununla birlikte elde bulunan mevcut veriler var ise tüm bu veriler bir araya getirilip büyük veri oluşturulur. <b className='bold'> Büyük Veri (Big Data)</b> sayısal, imaj, ses, video, metin vs. gibi farklı formatlarda olabilir. Retmes AI üzerinde bulunan haberleşme protokolleri ile sahadan toplanmak istenilen verileri toplar veya elde mevcut veriler var ise bunlar için entegrasyon servisleri ile verileri istenilen veritabanı platformunda alır.
              </p>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
            <Card className='ai-card' >
              <Accordion.Toggle as={Card.Header} className='justify-content-between' eventKey="1">
                <FaCircle style={{ fill: '#4688bd' }} />    <h3 className='bold'>Analiz</h3>    <FaSortDown />
              </Accordion.Toggle>
              <Accordion.Collapse eventKey="1">
                <Card.Body>
                  <h4 className='bold'>Veri Analizi</h4>
                  <p>
                    Oluşturulan  <b className='bold'>Büyük Veri (Big Data)</b> ile problemin çözümüne ilişkin veriler üzerinde modelleme aşamasına geçmeden önce analiz çalışmaları yapılarak problem çözümünde kullanılacak veri setleri için model çalışmaları için uygun, temiz, kullanılabilir veri seti/setlerinin çalışması yapılır. Retmes AI üzerinde entegre olan analiz yazılımları ve teknolojileri ile de veri analizi yapılabilir veya Retmes AI harici kullanılan DSP platformlarında da analiz edilmesi için gerekli olan veri aktarımı veya analiz çıktıları Retmes AI ortamına entegrasyon servisleri ile içeriye aktarılabilir.
            </p>
                  <h4 className='bold'>Veri Setlerinin Belirlenmesi</h4>
                  <p>
                    Veri Analizi ile birlikte model çalışmaları için kullanılacak veri setleri, modelleme için hazırlanır.
              </p>
                  <h4 className='bold'>Veri Görselleştirme</h4>
                  <p>
                    Hazırlanan veri setlerinin, problem çözümünde kullanılacak model için daha iyi anlaşılması, değişkenler arasındaki ilişkilerin belirlenebilmesi için en uygun veri görselleştirme yöntemleriyle veriler görselleştirilir.
              </p>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
            <Card className='ai-card'>
              <Accordion.Toggle as={Card.Header} className='justify-content-between' eventKey="2">
                <FaCircle style={{ fill: '#305468' }} /> <h3 className='bold'>Modelleme</h3>    <FaSortDown />
              </Accordion.Toggle>
              <Accordion.Collapse eventKey="2">
                <Card.Body>
                  <h4 className='bold'>Model Yaratma</h4>
                  <p>
                    Model için hazırlanan veri seti/setleri uygun <b className='bold'> DSP</b> platformaları ile Retmes AI içerisinde entegre şekilde model/modeller oluşturma çalışmaları yapılabilir veya tercih edilen bir DSP platformunda oluşturulmuş modeller Retmes AI sistemine entegre edilebilir.
            </p>
                  <h4 className='bold'>Model Eğitme</h4>
                  <p>
                    Uygun <b className='bold'> DSP</b>  platformuna karar verdikten sonra eğitim modeli oluşturmak için toplanmış <b className='bold'>  Büyük Veri</b> içerisinden veri analizi safhasında hazırlanmış veri setimiz eğitim modeli için eğitim ve test verisi olarak kullanılarak eğitim modeli oluşturulur.
            </p>
                  <h4 className='bold'>En İyi Model Seçimi</h4>
                  <p>
                    Yapay Zekâ projelerinde problem çözümüne ilişkin çok farklı yöntemler, algoritmalar ve yaklaşımlar geliştirmek mümkündür. Yapay Zekâ projelerinde problem çözümüne ilişkin Makine Öğrenimi, Derin Öğrenme gibi farklı katmanlarda model çalışmaları, algoritmaları ve yaklaşımları ile modeller oluşturulabilir. Problem çözümüne en uygun model için farklı yaklaşımlar ile farklı modeller oluşturulabilir.
            </p>
                  <h4 className='bold'>Model Değerlendirme</h4>
                  <p>
                    En iyi model seçimi için çözümde kullanılacak modele karar vermek için sahaya uygunluğu, problem çözümünde başarı oranı, teknik alt yapısı vs. gibi bir çok aşamada değerlendirme ve test çalışmaları sonucu problem çözümünde kullanılacak en iyi model seçilip karar verilir. Model değerlendirmesi sahadan gelecek sonuçlara veya olası değişiklikler ile tekrarlanıp yeni modeller oluşturma veya model iyileştirme çalışmaları yapılabilir.
            </p>
                  <h4 className='bold'>Model Yayınlama</h4>
                  <p>
                    Problem çözümüne ilişkin sahada otonom bir yapı oluşturmak için otomasyon sistemleri, karar destek çözümleri gibi farklı süreçlerde kullanılmak üzere entegrasyon araçları ile modeller yayınlanır.
            </p>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
            <Card className='ai-card'>
              <Accordion.Toggle as={Card.Header} className='justify-content-between' eventKey="3">
                <FaCircle style={{ fill: '#17bcaf' }} /><h3 className='bold'>Entegrasyon</h3>    <FaSortDown />
              </Accordion.Toggle>
              <Accordion.Collapse eventKey="3">
                <Card.Body>
                  <h4 className='bold'>Saha Entegrasyonu</h4>
                  <p>Sahadan toplanan canlı verilerin yayınlanmış modelimize girdi olarak tanımlanıp, model çıktılarının yapılan AI projesine göre çıktılarının alındığı aşamadır.  <b className='bold'>  Retmes AI </b> sisteminin sahadan canlı topladığı veriler girdi olarak tanımlanıp yine  <b className='bold'>  Retmes AI </b> üzerinden çıktılarının alındığı ve sistemin model çıktılarına göre davranışlarının tanımlandığı bu aşama, problem çözümünde AI modelimizin çıktılarına göre e-mail, sms, rapor gibi bilgilendirme, siren ile ışıklı-görsel uyarı araçları ile uyarı mekanizması, makine/hat/istasyon durdurma veya proses set değerlerinin değiştirilmesine kadar problemin çözümüne uygun model çıktıları uyarlama çalışmalarını içerir.</p>
                  <h4 className='bold'>Canlıya Geçiş</h4>
                  <p>Modelin sahaya entegrasyonu üzerine model çıktıları ve problem çözümüne ilişkin testler yapılarak AI modelimiz canlıya geçilir ve problem çözümü uçtan uca  <b className='bold'>  Retmes AI </b> üzerinde veri toplanmasından, analize, model çalışmalarından, yayınlanma sürecine kadar  <b className='bold'>  Retmes AI </b> üzerinden problem çözümü gerçekleştirilir.</p>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          </Accordion>
          <Row className='home-row-2' >
            <Col sm style={{ alignSelf: 'center', marginTop: '1em', marginBottom: '0px' }} className='head-col-1'  >
              <strong className='support-title'><FormattedMessage id="modules_ad_2_title_h1" /></strong> <br />
              <p style={{ fontWeight: '200', paddingTop: '30px', textAlign: '-webkit-center' }}><FormattedMessage id="solutions_ad_desc_p" /></p> <br />
              <Link to='/iletisim'> <div className='retmes-detail-button'><FormattedMessage id="get_support" /> </div></Link>
            </Col>
          </Row>
        </Container>
      </section>
    </Layout>
  )
}

export default injectIntl(RetmesAI)
